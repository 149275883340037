<template>
  <div>
    <Breadcrumbs page_title="participantinfo" :items="breadcrumbs" />
    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <v-container>
        <v-row class="mb-3 mt-1">
          <v-col cols="12" sm="12" md="6" lg="3">
            <v-text-field
              outlined
              dense
              clearable
              hide-details
              style="border-radius: 8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="6" lg="3" style="text-align: right">
            <v-btn
              outlined
              depressed
              color="#A6CC39"
              style="
                font-size: 16px;
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                height: 40px;
              "
              class="mr-3 text-capitalize rounded-lg"
              @click="exportExcel()"
            >
              <v-icon>mdi-file-upload-outline</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              class="participant-table"
              :headers="headers"
              hide-default-footer
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="search"
              :items="items"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="error"
                  style="font-size: 16px"
                  text
                  class="text-capitalize"
                  @click="(deleteDialog = true), (editData = item)"
                  >{{ $t("delete") }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="space-between" no-gutters>
          <v-col cols="auto" class="mr-auto">
            <v-pagination
              circle
              v-model="page"
              class="pagination"
              :length="pageCount"
            ></v-pagination>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <DeleteConfirmDialog
          :show="deleteDialog"
          :text="deleteTextMessage"
          :ispartcipant="true"
          @close="deleteDialog = false"
          @confirm="confirm($event)"
        />

        <SuccessDialog
          :show="successDialog"
          :title="$t('success')"
          :text="$t('deleteparticipant')"
          @close="successDialog = false"
        />
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
export default {
  data: () => ({
    search: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    editData: {
      id: 0,
    },
    deleteDialog: false,
    successDialog: false,
    loading: true,
  }),
  computed: {
    ...mapGetters(["event"]),
    breadcrumbs() {
      return [
        {
          // image: "house.png"
          sidebar_tilte: "home",
        },
        {
          text: "event",
          href: "/home/event",
        },
        {
          text: "calendar",
        },
        {
          text: this.event.courseName,
          href: "/home/event/preview/" + this.event.id,
        },
        {
          text: "participantinfo",
        },
      ];
    },
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("userid"),
          align: "left",
          value: "empID",
          class: "tableColor",
          sortable: false,
          width: "110px",
        },
        {
          text: this.$t("fullnamesm"), //userc
          align: "left",
          value: "fullName",
          class: "tableColor",
          sortable: false,
          width: "130px",
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "departmentName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("gender"),
          align: "left",
          value: "gender",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("Join Date"),
          align: "left",
          value: "signUpDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("delete"), //manage
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    ...mapGetters(["event"]),
    deleteTextMessage() {
      return `Are you sure you want to delete "#${this.editData.empID}" from ${this.event.courseName}?`;
    },
    getCollections() {
      return this.collections.map((collection) => ({
        ...collection,
        empID: `${collection.empID}`,
      }));
    },
  },
  async mounted() {
    if (!this.event.id) {
      await this.getData();
    }
    await this.getParticipant();
  },
  methods: {
    async getData() {
      const res = await this.$axios.post(
        `${this.web_urlV2}Courses/GetCourseByID`,
        {
          userID: parseInt(localStorage.getItem("UserID")),
          courseID: parseInt(this.$route.params.id),
        }
      );

      this.$store.commit("addEvent", res.data.data);
    },
    async getParticipant() {
      this.loading = true;
      const res = await this.$axios.post(
        `${this.web_url}Courses/GetJoinUsersByCourseID`,
        {
          courseID: this.event.id,
          page: 0,
          per_page: 0,
        }
      );
      this.items = res.data.data.map((v, i) => ({
        ...v,
        no: i + 1,
        signUpDate: moment(res.data.data[i].signUpDate)
          .local()
          .format("DD/MM/YYYY"),
      }));
      this.loading = false;
    },
    async confirm(chk) {
      let momentObj = moment(new Date().toLocaleTimeString(), ["hh:mm:ss"]);
      let newdate =
        moment(new Date()).format("DD/MM/YYYY") +
        " " +
        momentObj.format("HH:mm:ss");
      let request = [];
      if (chk != null) {
        request = {
          userID: this.editData.userID,
          courseID: this.event.id,
          joinCourse: false,
          date: newdate.toString(),
          inboxFlag: chk.inbox,
          pushNotiFlag: chk.pushnoti,
          emailFlag: chk.email,
        };
      } else {
        request = {
          userID: this.editData.userID,
          courseID: this.event.id,
          joinCourse: false,
          date: newdate.toString(),
        };
      }
      const res = await this.$axios.post(
        `${this.web_urlV2}Courses/CancelEnrollFromAdmin`,
        request
      );
      if (res.data.status == 0) {
        this.deleteDialog = false;
        this.successDialog = true;
        this.getParticipant();
      }
    },
    async exportExcel() {
      this.exportDialog = false;
      const headers = ["USERID", "USER", "GROUP", "EMAIL", "DATE"];
      const keys = [
        "empID",
        "fullName",
        "departmentName",
        "email",
        "signUpDate",
      ];
      const fileName = `${"ParticipantInfo"}.xlsx`;
      const filterItems = this.items.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);

      for (const item of filterItems) {
        let rowOne = [];

        for (const key of keys) {
          rowOne.push(item[key] || " ");
        }
        rowOne[headers.indexOf("USERID")] = item.empID;
        rowOne[headers.indexOf("USER")] = item.fullName;
        rowOne[headers.indexOf("GROUP")] = item.departmentName;
        rowOne[headers.indexOf("EMAIL")] = item.email;
        rowOne[headers.indexOf("DATE")] = item.signUpDate;
        data.push(rowOne);
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Participant Info", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell(function (cell) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell(function (cell) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .participant-table tr:nth-child(even) {
  background: unset;
  /* #e0e0e0 */
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}
</style>
